<template>
  <div class="container">
    <p>
      <b>{{ loginUser.name }}</b
      >同学：
    </p>
    <p>
      恭喜您！您已被我校 <b>{{ loginUser.majorName }}</b
      >专业录取。请持录取通知书按时到我校报到，报到时间、地点详见《广西外国语学院新生入学指南》。
    </p>
    <p class="right">广西外国语学院</p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    loginUser() {
      return this.$store.getters.user;
    },
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.container {
  padding: 10px;
  font-size: 14px;
  background-color: #fff;
}

.container p {
  text-indent: 2em;
}
.container p b {
  color: #ee0a24;
}
.container p.right {
  text-align: right;
}
</style>
